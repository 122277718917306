import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import AnnouncementModal from "../../components/AnnouncementModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));


const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_ANNOUNCEMENTS":
      return [...action.payload];
    case "UPDATE_ANNOUNCEMENTS":
      return state.map(item => item.id === action.payload.id ? action.payload : item);
    case "DELETE_ANNOUNCEMENT":
      return state.filter(item => item.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const Announcements = () => {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [deletingAnnouncement, setDeletingAnnouncement] = useState(null);
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [announcements, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchAnnouncements();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-announcement`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_ANNOUNCEMENTS", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_ANNOUNCEMENT", payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const { data } = await api.get("/announcements/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_ANNOUNCEMENTS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenAnnouncementModal = () => {
    setSelectedAnnouncement(null);
    setAnnouncementModalOpen(true);
  };

  const handleCloseAnnouncementModal = () => {
    setSelectedAnnouncement(null);
    setAnnouncementModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditAnnouncement = (announcement) => {
    setSelectedAnnouncement(announcement);
    setAnnouncementModalOpen(true);
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    try {
      await api.delete(`/announcements/${announcementId}`);
      toast.success(i18n.t("announcements.toasts.deleted"));
      dispatch({ type: "DELETE_ANNOUNCEMENT", payload: announcementId });
    } catch (err) {
      toastError(err);
    }
    setDeletingAnnouncement(null);
    setConfirmModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const translatePriority = (val) => {
    if (val === 1) return i18n.t("announcements.priority.high");
    if (val === 2) return i18n.t("announcements.priority.medium");
    if (val === 3) return i18n.t("announcements.priority.low");
  };

  const primaryButtons = [
    { label: i18n.t("announcements.buttons.add"), onClick: handleOpenAnnouncementModal },
  ];

  const columns = [
    { field: 'title', headerName: i18n.t("announcements.table.title"), headerStyle: {fontWeight: '400'},width: '20%' },
    {
      field: 'priority',
      headerName: i18n.t("announcements.table.priority"),
      renderCell: ({ row }) => translatePriority(row.priority),
      headerStyle: {fontWeight: '400'},width: '20%'
    },
    {
      field: 'mediaName',
      headerName: i18n.t("announcements.table.mediaName"),
      renderCell: ({ row }) => row.mediaName || i18n.t("announcements.table.noAttachment"),
      headerStyle: {fontWeight: '400'},width: '20%'
    },
    {
      field: 'status',
      headerName: i18n.t("announcements.table.status"),
      renderCell: ({ row }) => row.status ? i18n.t("announcements.status.active") : i18n.t("announcements.status.inactive"),
      headerStyle: {fontWeight: '400'},width: '20%'
    },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton size="small" onClick={() => handleEditAnnouncement(row)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setDeletingAnnouncement(row);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];
  const actionCellStyle = {
    width: '12%',
  };


  return (
    <>
      <div className={classes.mainContainer} >
        <ConfirmationModal
          title={deletingAnnouncement && `${i18n.t("announcements.confirmationModal.deleteTitle")} ${deletingAnnouncement.name}?`}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => handleDeleteAnnouncement(deletingAnnouncement.id)}
        >
          {i18n.t("announcements.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <AnnouncementModal
          open={announcementModalOpen}
          onClose={handleCloseAnnouncementModal}
          aria-labelledby="form-dialog-title"
          announcementId={selectedAnnouncement && selectedAnnouncement.id}
          resetPagination={() => {
            setPageNumber(1);
            fetchAnnouncements();
          }}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("announcements.title")}
            primaryButtons={primaryButtons}
            onSearch={handleSearch}
          />
          <TableManager
            columns={columns}
            data={announcements}
            actionCellStyle={actionCellStyle}
            loading={loading}
            onScroll={loadMore}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default Announcements;