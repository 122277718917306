/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CampaignModal from "../../components/CampaignModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import { isArray } from "lodash";
import { useDate } from "../../hooks/useDate";
import { socketConnection } from "../../services/socket";
import { Assessment } from "@material-ui/icons";
import TableManager from "../../components/TableManager/TableManager";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import DashBoardIcon from "../../assets/icon/DashBoardIcon";
import AddIcon from "../../assets/icon/AddIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));


const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CAMPAIGNS":
      return action.payload;
    case "UPDATE_CAMPAIGNS":
      return state.map(item => item.id === action.payload.id ? action.payload : item);
    case "DELETE_CAMPAIGN":
      return state.filter(item => item.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const Campaigns = () => {
  const history = useHistory();
  const { datetimeToClient } = useDate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [deletingCampaign, setDeletingCampaign] = useState(null);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [campaigns, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchCampaigns();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-campaign`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPAIGN", payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchCampaigns = async () => {
    try {
      const { data } = await api.get("/campaigns/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CAMPAIGNS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleOpenCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(false);
  };

  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalOpen(true);
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      await api.delete(`/campaigns/${campaignId}`);
      toast.success(i18n.t("campaigns.toasts.deleted"));
      dispatch({ type: "DELETE_CAMPAIGN", payload: campaignId });
    } catch (err) {
      toast.error(err.message);
    }
    setDeletingCampaign(null);
    setConfirmModalOpen(false);
  };

  const cancelCampaign = async (campaign) => {
    try {
      await api.post(`/campaigns/${campaign.id}/cancel`);
      toast.success(i18n.t("campaigns.toasts.cancel"));
      fetchCampaigns();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const restartCampaign = async (campaign) => {
    try {
      await api.post(`/campaigns/${campaign.id}/restart`);
      toast.success(i18n.t("campaigns.toasts.restart"));
      fetchCampaigns();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const formatStatus = (val) => {
    const statusMap = {
      "INATIVA": "Inactiva",
      "PROGRAMADA": "Programada",
      "EM_ANDAMENTO": "En proceso",
      "CANCELADA": "Cancelada",
      "FINALIZADA": "Finalizada"
    };
    return statusMap[val] || val;
  };

  const columns = [
    { field: 'name', headerName: i18n.t("campaigns.table.name") },
    {
      field: 'status',
      headerName: i18n.t("campaigns.table.status"),
      renderCell: ({ row }) => formatStatus(row.status)
      , headerStyle: { fontWeight: '400' }, width: '12%'
    },
    {
      field: 'contactList',
      headerName: i18n.t("campaigns.table.contactList"),
      renderCell: ({ row }) => row.contactListId ? row.contactList.name : "No definida",
      headerStyle: { fontWeight: '400' }, width: '13%'
    },
    {
      field: 'whatsapp',
      headerName: i18n.t("campaigns.table.whatsapp"),
      renderCell: ({ row }) => row.whatsappId ? row.whatsapp.name : "No definido",
      headerStyle: {fontWeight: '400'},width: '12%'
    },
    {
      field: 'scheduledAt',
      headerName: i18n.t("campaigns.table.scheduledAt"),
      renderCell: ({ row }) => row.scheduledAt ? datetimeToClient(row.scheduledAt) : "Sin agendamiento",
      headerStyle: {fontWeight: '400'},width: '12%'
    },
    {
      field: 'completedAt',
      headerName: i18n.t("campaigns.table.completedAt"),
      renderCell: ({ row }) => row.completedAt ? datetimeToClient(row.completedAt) : "No Completada",
      headerStyle: { fontWeight: '400' }, width: '12%'
    },
    {
      field: 'confirmation',
      headerName: i18n.t("campaigns.table.confirmation"),
      renderCell: ({ row }) => row.confirmation ? "Habilitada" : "Desabilitada",
      headerStyle: { fontWeight: '400' }, width: '12%'
    },
  ];

  const actionButtons = [
    ({ row }) => row.status === "EM_ANDAMENTO" && (
      <IconButton onClick={() => cancelCampaign(row)} title="Parar Campanha" size="small">
        <PauseCircleOutlineIcon />
      </IconButton>
    ),
    ({ row }) => row.status === "CANCELADA" && (
      <IconButton onClick={() => restartCampaign(row)} title="Reiniciar Campanha" size="small">
        <PlayCircleOutlineIcon />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton onClick={() => history.push(`/campaign/${row.id}/report`)} size="small">
        <DashBoardIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton size="small" onClick={() => handleEditCampaign(row)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setDeletingCampaign(row);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];
  const actionCellStyle = {
    width: '12%',
  };

  const primaryButtons = [
    { label: i18n.t("campaigns.buttons.add"), onClick: handleOpenCampaignModal, icon: <AddIcon fill='#000' /> },
  ];

  return (
    <>
      <div className={classes.mainContainer} >
        <ConfirmationModal
          title={deletingCampaign && `${i18n.t("campaigns.confirmationModal.deleteTitle")} ${deletingCampaign.name}?`}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
        >
          {i18n.t("campaigns.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <CampaignModal
          open={campaignModalOpen}
          onClose={handleCloseCampaignModal}
          aria-labelledby="form-dialog-title"
          campaignId={selectedCampaign && selectedCampaign.id}
          resetPagination={() => {
            setPageNumber(1);
            fetchCampaigns();
          }}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("campaigns.title")}
            primaryButtons={primaryButtons}
            onSearch={(value) => setSearchParam(value.toLowerCase())}
          />
          <TableManager
            columns={columns}
            data={campaigns}
            actionCellStyle={actionCellStyle}
            loading={loading}
            onScroll={() => hasMore && !loading && setPageNumber(prevState => prevState + 1)}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default Campaigns;