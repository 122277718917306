import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, TextField, DialogContent, DialogActions, Grid } from '@material-ui/core';
import PropType from 'prop-types'
import Dialog from '../Dialog';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { i18n } from '../../translate/i18n';
import { makeStyles } from '@material-ui/core/styles';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { AuthContext } from "../../context/Auth/AuthContext";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

import { isNil, isObject, has, get } from 'lodash';
import EmojiPicker from 'emoji-picker-react';

const MessageSchema = Yup.object().shape({
    shortcode: Yup.string()
        .min(3, "Muy corto!")
        .max(50, "Too Long!")
        .required("Required"),
    message: Yup.string()
        .min(3, "Muy corto!")
        .max(500, "Too Long!")
        .required("Required")
});


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '350px',
        },
    },
    list: {
        width: '100%',
        maxWidth: '350px',
        maxHeight: '200px',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        width: '100%'
    },
    emojiButton: {
        width: '30px', 
        height: '40px', 
        position: 'absolute',
        right: '10px',
        top: '60%',
    },
    textField: {
        width: 'calc(100% - 60px)',
    },
    emojiPickerStyle: {
        position: 'fixed',
        bottom:"90px",
        right: 'calc(50% - 170px)',
        zIndex: 10000,
        maxHeight: '300px',
        maxWidth: "400px",
        backgroundColor: 'white',
        boxShadow: theme.shadows[5], 
        borderRadius: '4px'
    }
}));
function QuickMessageDialog(props) {
    const classes = useStyles()

    const initialMessage = {
        id: null,
        shortcode: '',
        message: ''
    };

    const { modalOpen, saveMessage, editMessage, onClose, messageSelected } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState(initialMessage);
    const [loading, setLoading] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const messageRef = useRef(null);
    const shortcodeRef = useRef(null); 

    const { user } = useContext(AuthContext);

    useEffect(() => {
        verifyAndSetMessage()
        setDialogOpen(modalOpen)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen])

    useEffect(() => {
        verifyAndSetMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageSelected])

    const messageSelectedIsValid = () => {
        return isObject(messageSelected) && has(messageSelected, 'id') && !isNil(get(messageSelected, 'id'))
    }

    const verifyAndSetMessage = () => {
        if (messageSelectedIsValid()) {
            const { id, message, shortcode } = messageSelected
            setMessage({ id, message, shortcode })
        } else {
            setMessage(initialMessage)
        }
    }

    const handleClose = () => {
        onClose()
        setLoading(false)
    }

    const handleSave = async (values) => {
        if (messageSelectedIsValid()) {
            editMessage({
                ...messageSelected,
                ...values,
                userId: user.id
            });
        } else {
            saveMessage({
                ...values,
                userId: user.id
            });
        }
        handleClose()
    }
    

    const handleEmojiClick = (emojiObject,setFieldValue) => {
        const emoji = emojiObject.emoji || emojiObject.native || emojiObject;
        const field = messageRef.current;
        const currentShortcode = shortcodeRef.current.value;
      
        if (field) {
            const start = field.selectionStart || 0;
            const end = field.selectionEnd || 0;
            const text = field.value || "";
            const newText = text.substring(0, start) + emoji + text.substring(end);

            setMessage((prev) => ({
                ...prev,
                message: newText,
            }));
            field.focus();
            setTimeout(() => {
                field.setSelectionRange(start + emoji.length, start + emoji.length);
            }, 0);
            setFieldValue("shortcode", currentShortcode);
        }
    };

    return (
        <Dialog
            title="Mensaje Rápido"
            modalOpen={dialogOpen}
            onClose={handleClose}
        >
            <Formik
                initialValues={message}
                enableReinitialize={true}
                validationSchema={MessageSchema}
                onSubmit={(values, actions) => {
                    setLoading(true);
                    setTimeout(() => {
                        handleSave(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, setFieldValue }) => (
                    <Form>
                        <DialogContent className={classes.root} dividers >
                            <Grid direction="column" container>
                                <Grid item>
                                    <Field
                                        as={TextField}
                                        name="shortcode"
                                        label={i18n.t("quickMessages.dialog.shortcode")}
                                        error={touched.shortcode && Boolean(errors.shortcode)}
                                        helperText={touched.shortcode && errors.shortcode}
                                        variant="outlined"
                                        inputRef={shortcodeRef}
                                    />
                                </Grid>
                                <Grid item style={{ position: 'relative' }}>
                                    <Field
                                        as={TextField}
                                        name="message"
                                        inputRef={messageRef}
                                        rows={6}
                                        label={i18n.t("quickMessages.dialog.message")}
                                        multiline={true}
                                        error={touched.message && Boolean(errors.message)}
                                        helperText={touched.message && errors.message}
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.emojiButton}
                                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                    >
                                        <InsertEmoticonIcon />
                                    </Button>
                                    <div>
                                    {showEmojiPicker && (
                                        <div className={classes.emojiPickerStyle}>
                                            <EmojiPicker onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject, setFieldValue)} />
                                        </div>
                                    )}
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancelar
                            </Button>
                            <ButtonWithSpinner loading={loading} color="primary" type="submit" variant="contained" autoFocus>
                                Guardar
                            </ButtonWithSpinner>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

QuickMessageDialog.propType = {
    modalOpen: PropType.bool,
    onClose: PropType.func
}

export default QuickMessageDialog;