import React, { useState, useEffect, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { makeStyles, Button, Paper, IconButton } from "@material-ui/core";

import QuickMessagesTable from "../../components/QuickMessagesTable";
import QuickMessageDialog from "../../components/QuickMessageDialog";
import ConfirmationModal from "../../components/ConfirmationModal";

import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";

import useQuickMessages from "../../hooks/useQuickMessages";
import { AuthContext } from "../../context/Auth/AuthContext";
import TableManager from "../../components/TableManager/TableManager";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import AddIcon from "../../assets/icon/AddIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));

function QuickMessages() {
  const [messages, setMessages] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [messageSelected, setMessageSelected] = useState({});
  const [showOnDeleteDialog, setShowOnDeleteDialog] = useState(false);

  const {
    list: listMessages,
    save: saveMessage,
    update: updateMessage,
    deleteRecord: deleteMessage,
  } = useQuickMessages();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    loadingQuickMessages();
  }, []);

  const loadingQuickMessages = async () => {
    setLoading(true);
    try {
      const companyId = localStorage.getItem("companyId");
      const messages = await listMessages({ companyId, userId: user.id });
      setMessages(messages);
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const handleOpenToAdd = () => {
    setMessageSelected({ id: null, message: "", shortcode: "" });
    setModalOpen(true);
  };

  const handleOpenToEdit = (message) => {
    setMessageSelected(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMessageSelected({ id: null, message: "", shortcode: "" });
  };

  const handleSave = async (message) => {
    handleCloseModal();
    try {
      await saveMessage(message);
      await loadingQuickMessages();
      toast.success(i18n.t("quickMessages.toasts.success"));
    } catch (e) {
      toast.error(i18n.t("quickMessages.toasts.error"));
    }
  };

  const handleEdit = async (message) => {
    handleCloseModal();
    try {
      await updateMessage(message);
      await loadingQuickMessages();
      toast.success(i18n.t("quickMessages.toasts.successEdit"));
    } catch (e) {
      toast.error(i18n.t("quickMessages.toasts.errorEdit"));
    }
  };

  const handleDelete = async (message) => {
    handleCloseModal();
    try {
      await deleteMessage(message.id);
      await loadingQuickMessages();
      toast.success(i18n.t("quickMessages.toasts.successDelete"));
    } catch (e) {
      toast.error(e);
    }
  };

  const primaryButtons = [
    { label: i18n.t("quickMessages.buttons.add"), onClick: handleOpenToAdd, icon: <AddIcon fill='#000' /> },
  ];

  const columns = [
    { field: 'shortcode', headerName: i18n.t("quickMessages.table.shortcode"), headerStyle: {fontWeight: '400'},width: '40%' },
    { field: 'message', headerName: i18n.t("quickMessages.table.message"),headerStyle: {fontWeight: '400'},width: '40%' },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton size="small" onClick={() => handleOpenToEdit(row)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setMessageSelected(row);
          setShowOnDeleteDialog(true);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  return (
    <>
      <div className={classes.mainContainer} >
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("quickMessages.title")}
            primaryButtons={primaryButtons}
          />
          <TableManager
            columns={columns}
            data={messages}
            loading={loading}
            actionButtons={actionButtons}
          />
        </div>
        <QuickMessageDialog
          messageSelected={messageSelected}
          modalOpen={modalOpen}
          onClose={handleCloseModal}
          editMessage={handleEdit}
          saveMessage={handleSave}
        />
        <ConfirmationModal
          title={i18n.t("quickMessages.confirmationModal.deleteTitle")}
          open={showOnDeleteDialog}
          onClose={() => setShowOnDeleteDialog(false)}
          onConfirm={async () => {
            await handleDelete(messageSelected);
            setShowOnDeleteDialog(false);
          }}
        >
          {i18n.t("quickMessages.confirmationModal.deleteMessage")}
        </ConfirmationModal>
      </div>
    </>
  );
}

export default QuickMessages;