const ChatIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.375 7.875H11.625" stroke={fill} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.25 13.8225H8.25L11.5875 16.0425C12.0825 16.3725 12.75 16.02 12.75 15.42V13.8225C15 13.8225 16.5 12.3225 16.5 10.0725V5.57251C16.5 3.32251 15 1.82251 12.75 1.82251H5.25C3 1.82251 1.5 3.32251 1.5 5.57251V10.0725C1.5 12.3225 3 13.8225 5.25 13.8225Z" stroke={fill} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export default ChatIcon