import React, { useState, useEffect, useContext, useCallback, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import TagModal from "../../components/TagModal";
import { Button, IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ConfirmationModal from "../../components/ConfirmationModal";
import EditIcon from "@material-ui/icons/Edit";


const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_TAGS": {
      const tags = action.payload;
      return tags.sort((a, b) => a.order - b.order);
    }
    case "UPDATE_TAGS": {
      const tag = action.payload;
      const tagIndex = state.findIndex((s) => s.id === tag.id);

      if (tagIndex !== -1) {
        return state.map((t) => (t.id === tag.id ? tag : t));
      } else {
        return [...state,tag];
      }
    }

    case "DELETE_TAG": {
      const tagId = action.payload;
      return state.filter((tag) => tag.id !== tagId);
    }

    case "RESET":
      return [];

    default:
      return state;
  }
};


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    position: "relative",
  },
  buttonContainer: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  title: {
    color: "#2196F3",
    padding: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.5rem",
    width: "fit-content",
    marginRight: "auto",
  },
}));
const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [rtags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingTag, setDeletingTag] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [file, setFile] = useState({ lanes: [] });
  const { user } = useContext(AuthContext);
  const { queues } = user;
  const jsonString = queues.map(queue => queue.UserQueue.queueId);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      const defaultLaneOrder = response.data.defaultLaneOrder || 0;
      dispatch({ type: "LOAD_TAGS", payload: fetchedTags });
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  const popularCards = () => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("tagsKanban.laneDefault"),
        label: filteredTickets.length,
        order: 0,
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          label: "Ticket nº " + ticket.id.toString(),
          description: (
            <div>
              <p>
                {ticket.contact.number}
                <br />
                {ticket.lastMessage}
              </p>
              <button
                className={classes.button}
                onClick={() => handleCardClick(ticket.uuid)}>
                Ver chat
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
          tags: []
        })),
      },
      ...rtags.sort((a, b) => a.order - b.order)
        .map(tag => {
          const filteredTickets = tickets.filter(ticket => {
            const tagIds = ticket.tags.map(tag => tag.id);
            return tagIds.includes(tag.id);
          });

          return {
            id: tag.id.toString(),
            title: tag.name,
            label: (
              <div>
                {filteredTickets.length.toString()}
                <IconButton size="small" onClick={() => handleEditTag(tag)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDeleteClick(tag)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            ),
            cards: filteredTickets.map(ticket => ({
              id: ticket.id.toString(),
              label: "Ticket nº " + ticket.id.toString(),
              description: (
                <div>
                  <p>
                    {ticket.contact.number}
                    <br />
                    {ticket.lastMessage}
                  </p>
                  <button
                    className={classes.button}
                    onClick={() => handleCardClick(ticket.uuid)}>
                    Ver Ticket
                  </button>
                </div>
              ),
              title: ticket.contact.name,
              draggable: true,
              href: "/tickets/" + ticket.uuid,
            })),
            style: { backgroundColor: tag.color, color: "white" }
          };
        }),
    ];

    setFile({ lanes });
  };

  useEffect(() => {
    popularCards();
  }, [rtags, tickets]);

  const handleCardClick = (uuid) => {
    history.push('/tickets/' + uuid);
  };

  const handleLaneMove = async (removedIndex, addedIndex, payload) => {
    console.log("Se movió una etiqueta");

    const tagLanes = rtags.filter(tag => tag.id !== 'lane0');

    const adjustedRemovedIndex = removedIndex - 1;
    const adjustedAddedIndex = addedIndex - 1;

    const newTags = Array.from(tagLanes);
    const [reorderedTag] = newTags.splice(adjustedRemovedIndex, 1);
    newTags.splice(adjustedAddedIndex, 0, reorderedTag);

    const updatedTags = newTags.map((tag, index) => ({
      ...tag,
      order: index,
      id: tag.id
    }));
  
    console.log("Etiquetas actualizadas:", updatedTags);
    dispatch({ type: "LOAD_TAGS", payload: updatedTags });
  
    try {
      await api.put("/tags/reorder", { tags: updatedTags });
    } catch (error) {
      console.error("Error al actualizar el orden de las etiquetas:", error);
      toast.error("No se pudo actualizar el orden de las etiquetas");
      fetchTags();
    }
  };
  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
        
          await api.delete(`/ticket-tags/${targetLaneId}`);
          await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);

    } catch (err) {
      console.log(err);
    }
  };
  const handleDeleteClick = (tag) => {
    setDeletingTag(tag);
    setConfirmModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      dispatch({ type: "DELETE_TAG", payload: tagId });
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
    setConfirmModalOpen(false);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Embudos
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenTagModal}
        >
          {i18n.t("tagsKanban.buttons.add")}
        </Button>
      </div>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
        kanban={1}
        dispatch={dispatch}
      />
      <ConfirmationModal
        title={i18n.t("tagsKanban.confirmationModal.deleteTitle")}
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => deletingTag && handleDeleteTag(deletingTag.id)}
      >
        {i18n.t("tagsKanban.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Board
        data={file}
        handleLaneDragEnd={handleLaneMove}
        onCardMoveAcrossLanes={handleCardMove}
        draggable
        laneDraggable={true}
        style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
      />
    </div>
  );
};

export default Kanban;