import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";

import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactListItemModal from "../../components/ContactListItemModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import useContactLists from "../../hooks/useContactLists";
import { Grid, useTheme } from "@material-ui/core";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import planilhaExemplo from "../../assets/planilha.xlsx";
import { socketConnection } from "../../services/socket";
import { CSVLink } from "react-csv";
import AddIcon from "../../assets/icon/AddIcon";
import ArrowUpIcon from "../../assets/icon/ArrowUpIcon";
import BackArrow from "../../assets/icon/BackArrow";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CONTACTS":
      return action.payload;
    case "UPDATE_CONTACTS":
      return state.map(item => item.id === action.payload.id ? action.payload : item);
    case "DELETE_CONTACT":
      return state.filter(item => item.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const ContactListItems = () => {
  const { user } = useContext(AuthContext);
  const { contactListId } = useParams();
  const history = useHistory();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactListItemModalOpen, setContactListItemModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [contactList, setContactList] = useState({});
  const fileUploadRef = useRef(null);

  const { findById: findContactList } = useContactLists();

  useEffect(() => {
    findContactList(contactListId).then((data) => {
      setContactList(data);
    });
  }, [contactListId, findContactList]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, contactListId]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-ContactListItem`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.id });
      }
      if (data.action === "reload") {
        dispatch({ type: "LOAD_CONTACTS", payload: data.records });
      }
    });

    socket.on(`company-${companyId}-ContactListItem-${contactListId}`, (data) => {
      if (data.action === "reload") {
        dispatch({ type: "LOAD_CONTACTS", payload: data.records });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [contactListId]);

  const fetchContacts = async () => {
    try {
      const { data } = await api.get(`contact-list-items`, {
        params: { searchParam, pageNumber, contactListId },
      });
      dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(true);
  };

  const handleCloseContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(false);
  };

  const handleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactListItemModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contact-list-items/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
      dispatch({ type: "DELETE_CONTACT", payload: contactId });
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setConfirmOpen(false);
  };

  const handleImportContacts = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileUploadRef.current.files[0]);
      await api.request({
        url: `contact-lists/${contactListId}/upload`,
        method: "POST",
        data: formData,
      });
      toast.success(i18n.t("contactListItems.toasts.imported"));
      fetchContacts();
    } catch (err) {
      toastError(err);
    }
    setConfirmOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const goToContactLists = () => {
    history.push("/contact-lists");
  };

  const primaryButtons = [
    { label: i18n.t("contactListItems.buttons.lists"), onClick: goToContactLists,icon: <BackArrow fill='#000' /> },
    { 
      label: i18n.t("contactListItems.buttons.import"), 
      icon: <ArrowUpIcon fill='#000' />,
      onClick: () => { fileUploadRef.current.click();
       } 
    },
    { label: i18n.t("contactListItems.buttons.add"), onClick: handleOpenContactListItemModal, icon: <AddIcon fill='#000' />  },
  ];

  const columns = [
    { 
      field: 'isWhatsappValid', 
      headerName: '#',
      align: 'center',
      renderCell: ({ row }) => (
        <IconButton>
          {row.isWhatsappValid ? (
            <CheckCircleIcon titleAccess="Whatsapp Válido" htmlColor="green" />
          ) : (
            <BlockIcon titleAccess="Whatsapp Inválido" htmlColor="grey" />
          )}
        </IconButton>
      )
    },
    { field: 'name', headerName: i18n.t("contactListItems.table.name") },
    { field: 'number', headerName: i18n.t("contactListItems.table.number"), align: 'center' },
    { field: 'email', headerName: i18n.t("contactListItems.table.email"), align: 'center' },
  ];

  const actionButtons = [
    ({row}) => (
      <IconButton size="small" onClick={() => handleEditContact(row.id)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({row}) => (
      <Can
        role={user.profile}
        perform="contacts-page:deleteContact"
        yes={() => (
          <IconButton
            size="small"
            onClick={() => {
              setConfirmOpen(true);
              setDeletingContact(row);
            }}
          >
            <TrashIcon fill="#7F7F7F" />
          </IconButton>
        )}
      />
    ),
  ];

  return (
    <>
      <ContactListItemModal
        open={contactListItemModalOpen}
        onClose={handleCloseContactListItemModal}
        contactId={selectedContactId}
      />
      <ConfirmationModal
        title={deletingContact
          ? `${i18n.t("contactListItems.confirmationModal.deleteTitle")} ${deletingContact.name}?`
          : `${i18n.t("contactListItems.confirmationModal.importTitlte")}`}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => deletingContact ? handleDeleteContact(deletingContact.id) : handleImportContacts()}
      >
        {deletingContact
          ? i18n.t("contactListItems.confirmationModal.deleteMessage")
          : (
            <>
              {i18n.t("contactListItems.confirmationModal.importMessage")}
              <a href={planilhaExemplo} download="planilha.xlsx">
                Clique aqui para baixar planilha exemplo.
              </a>
            </>
          )
        }
      </ConfirmationModal>
      <input
        style={{ display: "none" }}
        id="upload"
        name="file"
        type="file"
        accept=".xls,.xlsx"
        onChange={() => setConfirmOpen(true)}
        ref={fileUploadRef}
      />
      <HeaderManager
        title={contactList.name}
        primaryButtons={primaryButtons}
        onSearch={handleSearch}
      />
      <TableManager
        columns={columns}
        data={contacts}
        loading={loading}
        onScroll={loadMore}
        actionButtons={actionButtons}
        loadingComponent={<TableRowSkeleton columns={4} />}
      />
    </>
  );
};

export default ContactListItems;