const NotificationBellIcon = ({ fill = "none" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.01494 2.1825C6.53244 2.1825 4.51494 4.2 4.51494 6.6825V8.85C4.51494 9.3075 4.31994 10.005 4.08744 10.395L3.22494 11.8275C2.69244 12.7125 3.05994 13.695 4.03494 14.025C7.26744 15.105 10.7549 15.105 13.9874 14.025C14.8949 13.725 15.2924 12.6525 14.7974 11.8275L13.9349 10.395C13.7099 10.005 13.5149 9.3075 13.5149 8.85V6.6825C13.5149 4.2075 11.4899 2.1825 9.01494 2.1825Z" stroke="#B2B2B2" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M10.4024 2.40002C10.1699 2.33252 9.92994 2.28002 9.68244 2.25002C8.96244 2.16002 8.27244 2.21252 7.62744 2.40002C7.84494 1.84502 8.38494 1.45502 9.01494 1.45502C9.64494 1.45502 10.1849 1.84502 10.4024 2.40002Z" stroke="#B2B2B2" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.2651 14.295C11.2651 15.5325 10.2526 16.545 9.01514 16.545C8.40014 16.545 7.83014 16.29 7.42514 15.885C7.02014 15.48 6.76514 14.91 6.76514 14.295" stroke="#B2B2B2" stroke-width="1.125" stroke-miterlimit="10" />
    </svg>

  )
}

export default NotificationBellIcon