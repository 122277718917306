import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { Link as RouterLink, useParams } from 'react-router-dom';
import VenttifyIcon from "../../assets/icon/VenttifyIcon.js";
import { openApi } from "../../services/api";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "70%",
        marginTop: "40px",
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(3),
        fontWeight: 'bold',
    },
    message: {
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    button: {
        fontWeight: "bold",
        fontSize: "16px",
        textTransform: 'none',
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        color: '#1D1C18',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            backgroundColor: theme.palette.primary.main,
        },
    },
    loader: {
        marginBottom: theme.spacing(3),
    },
}));

const FormVerifyAccount = () => {
    const classes = useStyles();
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        localStorage.clear();
        const verifyToken = async () => {
            try {
                await openApi.get(`/verify/${token}`);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(true);
            }
        };

        if (token) {
            verifyToken();
        } else {
            setLoading(false);
            setError(true);
        }
    }, [token]);

    return (
        <div className={classes.paper}>
            <div className={classes.logo}>
                <VenttifyIcon fill={theme.palette.primary.main}/>
                <Typography component="p" variant="subtitle1" style={{fontWeight: "bold", padding: "5px"}}>
                    Venttify
                </Typography>
            </div>
            {loading ? (
                <>
                    <CircularProgress className={classes.loader} />
                    <Typography variant="h6" className={classes.message}>
                        Verificando tu cuenta...
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="h4" className={classes.title}>
                        {error ? 'Error al verificar la cuenta' : 'Cuenta verificada'}
                    </Typography>
                    <Typography variant="body1" className={classes.message}>
                        {error
                            ? 'El token de verificación es inválido o ha expirado.'
                            : 'Tu cuenta ha sido verificada exitosamente. Ahora puedes iniciar sesión.'}
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/login"
                        className={classes.button}
                    >
                        Ir al login
                    </Button>
                </>
            )}
        </div>
    );
};

export default FormVerifyAccount;