const TaskBoardIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2449 7.82988L15.5099 10.9649C14.8799 13.6724 13.6349 14.7674 11.2949 14.5424C10.9199 14.5124 10.5149 14.4449 10.0799 14.3399L8.81989 14.0399C5.69239 13.2974 4.72489 11.7524 5.45989 8.61738L6.19489 5.47488C6.34489 4.83738 6.52489 4.28238 6.74989 3.82488C7.62739 2.00988 9.11989 1.52238 11.6249 2.11488L12.8774 2.40738C16.0199 3.14238 16.9799 4.69488 16.2449 7.82988Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.295 14.5425C10.83 14.8575 10.245 15.12 9.53251 15.3525L8.34751 15.7425C5.37001 16.7025 3.80251 15.9 2.83501 12.9225L1.87501 9.95995C0.915012 6.98245 1.71001 5.40745 4.68751 4.44745L5.87251 4.05745C6.18001 3.95995 6.47251 3.87745 6.75001 3.82495C6.52501 4.28245 6.34501 4.83745 6.19501 5.47495L5.46001 8.61745C4.72501 11.7525 5.69251 13.2975 8.82001 14.04L10.08 14.34C10.515 14.445 10.92 14.5125 11.295 14.5425Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export default TaskBoardIcon