const SubscriptionIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.50391 10.7474C6.50391 11.7149 7.24641 12.4949 8.16891 12.4949H10.0514C10.8539 12.4949 11.5064 11.8124 11.5064 10.9724C11.5064 10.0574 11.1089 9.73488 10.5164 9.52488L7.49391 8.47488C6.90141 8.26488 6.50391 7.94238 6.50391 7.02738C6.50391 6.18738 7.15641 5.50488 7.95891 5.50488H9.84141C10.7639 5.50488 11.5064 6.28488 11.5064 7.25238" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 4.5V13.5" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export default SubscriptionIcon