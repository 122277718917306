import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import api from '../../services/api';

const ImportExcelButton = ({ 
  endpoint, 
  onSuccess, 
  onError, 
  buttonText, 
  successMessage, 
  errorMessage,
  color = "#0F9D58",
  acceptedFileTypes = '.xlsx, .xls, .csv',
  variant,
}) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(successMessage);
      if (onSuccess) onSuccess(response.data);
    } catch (err) {
      toast.error(errorMessage);
      if (onError) onError(err);
    }
  }, [endpoint, onSuccess, onError, successMessage, errorMessage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    maxFiles: 1,
  });
  return (
    <div {...getRootProps()} style={{ display: 'inline-block' }}>
      <input {...getInputProps()} />
      <Button 
      variant={isDragActive ? 'outlined' : 'contained'}
        style={{ 
          backgroundColor: "#0F9D58",
          color: isDragActive ? '#000' : '#FFFFFF',
          transition: 'background-color 0.3s',
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ImportExcelButton;