import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import TableManager from "../../components/TableManager/TableManager";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TrashIcon from "../../assets/icon/TrashIcon";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import AddIcon from "../../assets/icon/AddIcon";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));


const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_USERS":
      return [...action.payload];
    case "UPDATE_USERS":
      return state.map(user => user.id === action.payload.id ? action.payload : user);
    case "DELETE_USER":
      return state.filter(user => user.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [users, dispatch] = useReducer(reducer, []);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [adminCount, setAdminCount] = useState(0);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const admins = users.filter(user => user.profile === 'admin');
    setAdminCount(admins.length);
  }, [users]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-user`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const currentUserId = localStorage.getItem("userId");
    setLoggedInUserId(parseInt(currentUserId));
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await api.get("/users/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_USERS", payload: data.users });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    if (userId === loggedInUserId) {
      toast.error(i18n.t("users.toasts.cantDeleteYourself"));
      return;
    }

    try {
      await api.delete(`/users/${userId}`);
      toast.success(i18n.t("users.toasts.deleted"));
      dispatch({ type: "DELETE_USER", payload: userId });
    } catch (err) {
      toastError(err);
    }
    setDeletingUser(null);
    setConfirmModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const primaryButtons = [
    { label: i18n.t("users.buttons.add"), onClick: handleOpenUserModal, icon: <AddIcon fill='#000' /> },
  ];

  const columns = [
    { field: 'name', headerName: i18n.t("users.table.name"), headerStyle: { fontWeight: '400' }, width: '28%' },
    { field: 'email', headerName: i18n.t("users.table.email"), headerStyle: { fontWeight: '400' }, width: '28%' },
    { field: 'profile', headerName: i18n.t("users.table.profile"), headerStyle: { fontWeight: '400' }, width: '28%' },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => handleEditUser(row)}
      >
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setDeletingUser(row);
        }}
        disabled={row.id === loggedInUserId}
        style={{ color: row.id === loggedInUserId ? 'grey' : 'inherit' }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  return (
    <>
      <div className={classes.mainContainer} >
        <ConfirmationModal
          title={deletingUser && `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser.name}?`}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => handleDeleteUser(deletingUser.id)}
        >
          {i18n.t("users.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <UserModal
          open={userModalOpen}
          onClose={handleCloseUserModal}
          aria-labelledby="form-dialog-title"
          userId={selectedUser && selectedUser.id}
          usersLength={users.length}
          adminCount={adminCount}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("users.title")}
            primaryButtons={primaryButtons}
            onSearch={handleSearch}
          />
          <TableManager
            columns={columns}
            data={users}
            loading={loading}
            onScroll={loadMore}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default Users;