const AddIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6 9H12" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 12V6" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export default AddIcon