const TrayIcon = ({ fill = "none" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="#B2B2B2" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.5 9.74999H4.32C4.89 9.74999 5.4075 10.0725 5.6625 10.5825L6.33 11.925C6.75 12.75 7.5 12.75 7.68 12.75H10.3275C10.8975 12.75 11.415 12.4275 11.67 11.9175L12.3375 10.575C12.5925 10.065 13.11 9.74249 13.68 9.74249H16.485" stroke="#B2B2B2" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export default TrayIcon