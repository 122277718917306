const BrightnessIcon = ({ fill = "none" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M9 13.875C11.6924 13.875 13.875 11.6924 13.875 9C13.875 6.30761 11.6924 4.125 9 4.125C6.30761 4.125 4.125 6.30761 4.125 9C4.125 11.6924 6.30761 13.875 9 13.875Z" stroke="#B2B2B2" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.00841 9.12665C6.11993 10.6713 7.4736 11.9279 9.09367 11.9969C10.2367 12.0449 11.2589 11.529 11.8723 10.7162C12.1263 10.3833 11.99 10.1614 11.5656 10.2364C11.358 10.2724 11.1443 10.2874 10.9213 10.2784C9.40653 10.2184 8.16747 8.99169 8.16128 7.54306C8.15818 7.15316 8.24181 6.78425 8.3936 6.44834C8.56087 6.07643 8.35952 5.89948 7.97232 6.05844C6.74565 6.55931 5.90619 7.756 6.00841 9.12665Z" stroke="#B2B2B2" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.355 14.355L14.2575 14.2575M14.2575 3.7425L14.355 3.645L14.2575 3.7425ZM3.645 14.355L3.7425 14.2575L3.645 14.355ZM9 1.56V1.5V1.56ZM9 16.5V16.44V16.5ZM1.56 9H1.5H1.56ZM16.5 9H16.44H16.5ZM3.7425 3.7425L3.645 3.645L3.7425 3.7425Z" stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export default BrightnessIcon