import React from 'react';
import { Box } from '@material-ui/core';

export const SideImage = () => {
  return (
    <Box
      sx={{
        width: '50vw',
        height: '95%',
        backgroundColor: '#f0f0f0',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <img
        src={require('../../assets/img/loginPhoto.png')}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        alt="Login Illustration"
      />
    </Box>
  );
};