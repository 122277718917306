import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
  Box,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from "../../services/api";
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  card: {
    maxWidth: 345,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  price: {
    fontSize: '2rem',
    margin: theme.spacing(2, 0),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  selectButton: {
    marginTop: theme.spacing(2),
  },
}));

const CheckoutModal = ({ open, handleClose, user }) => {
  const classes = useStyles();
  const [users, setUsers] = useState(0);
  const [connections, setConnections] = useState(0);
  const [loading, setLoading] = useState(false)
  const subscribed = user?.company?.subscribed;
  const trialExpirationDate = user?.company?.dueDate;
  const totalDays = 30;
  let daysLeft = totalDays;
  let dailyRateUsers
  let dailyRateConnections
  
  if (subscribed) {
    const currentPeriodEnd = Math.floor(new Date(trialExpirationDate).getTime() / 1000);
    const currentDate = Math.floor(Date.now() / 1000);
    daysLeft = Math.ceil((currentPeriodEnd - currentDate) / 86400);
    if (daysLeft > 30) {
      daysLeft = 30
    }
  }

  const calculateTotalPrice = () => {
    const additionalUsersPrice = users * 15;
    const additionalConnectionsPrice = connections * 20;
    
    if (subscribed) {
       dailyRateUsers = ((15 / totalDays) * daysLeft).toFixed(2);
       dailyRateConnections = ((20 / totalDays) * daysLeft).toFixed(2);
      return additionalUsersPrice * (dailyRateUsers / 15) + additionalConnectionsPrice * (dailyRateConnections / 20);
    }
    
    const basePrice = 49;
    return basePrice + additionalUsersPrice + additionalConnectionsPrice;
  };

  const handleUsersChange = (change) => {
    setUsers((prev) => Math.max(0, prev + change));
  };

  const handleConnectionsChange = (change) => {
    setConnections((prev) => Math.max(0, prev + change));
  };

  const handlePayment = async () => {
    try {
      setLoading(true)
      const response = await api.post('/create-checkout-session', {
        companyId: user.company.id,
        additionalUsers: users,
        additionalConnections: connections,
      });
      setLoading(false)
      console.log(response.data);
      window.location.href = response.data.url;
    } catch (error) {
      toast.error('Error creating checkout session');
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Card className={classes.card}>
            <CardContent>
              {subscribed ? (
                <>
                  <Typography gutterBottom variant="h5" component="h2">
                    Conexiones y Usuarios Adicionales
                  </Typography>
                  <Typography className={classes.price} color="textPrimary" component="p">
                    ${calculateTotalPrice()}/mes
                  </Typography>
                  <Typography color="textSecondary" component="p" style={{marginBottom : "10px"}}>
                  Por cada usuario o conexión adicional se cobrará el equivalente al tiempo faltante de su suscripción mensual.
                  </Typography>
                  <Typography color="textSecondary" component="p">
                    Usuario: ${dailyRateUsers}
                  </Typography>
                  <Typography color="textSecondary" component="p">
                    Conexion: ${dailyRateConnections}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography gutterBottom variant="h5" component="h2">
                    Plan Start
                  </Typography>
                  <Typography className={classes.price} color="textPrimary" component="p">
                    ${calculateTotalPrice()}/mes
                  </Typography>
                  <Typography color="textPrimary" component="p">
                    3 Usuarios
                  </Typography>
                  <Typography color="textPrimary" component="p">
                    1 Conexión
                  </Typography>
                  <Typography color="textSecondary" component="p" style={{ marginTop: "10px" }}>
                  Seleccione número de conexiones y usuarios adicionales que desea obtener
                  </Typography>
                </>
              )}
              <div className={classes.buttonGroup}>
                <Typography>Usuarios</Typography>
                <IconButton onClick={() => handleUsersChange(-1)}><RemoveIcon /></IconButton>
                <Typography>{users}</Typography>
                <IconButton onClick={() => handleUsersChange(1)}><AddIcon /></IconButton>
              </div>
              <div className={classes.buttonGroup}>
                <Typography style={{ marginRight: "-20px" }}>Conexiones</Typography>
                <IconButton onClick={() => handleConnectionsChange(-1)}><RemoveIcon /></IconButton>
                <Typography>{connections}</Typography>
                <IconButton onClick={() => handleConnectionsChange(1)}><AddIcon /></IconButton>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={classes.selectButton}
                disabled={loading}
                onClick={handlePayment}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {subscribed ? 'Agregar Adicionales' : 'Suscribirse'}
              </Button>
            </CardContent>
          </Card>
        </div>
      </Fade>
    </Modal>
  );
};

export default CheckoutModal;