import React, { useState, useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Button, InputAdornment, IconButton, TextField, Grid, Link } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json'
import 'react-phone-input-2/lib/material.css';
import { AuthContext } from "../../context/Auth/AuthContext.js";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError.js";
import { Business, LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import moment from "moment";
import { openApi } from "../../services/api.js";
import VenttifyIcon from "../../assets/icon/VenttifyIcon.js";
import { EmailIcon } from "../../assets/icon/EmailIcon.js";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "70%",
        marginTop: "40px",
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    title: {
        fontWeight: 'bold',
    },
    input: {
        marginTop: "30px",
        '& .MuiInputBase-input': {
            '&::placeholder': {
                color: theme.palette.text.main,
                opacity: 1,
                [theme.breakpoints.down('sm')]: {
                    fontSize: "14px"
                },
            },
        },
    },
    submit: {
        fontWeight: "bold",
        fontSize: "16px",
        textTransform: 'none',
        marginBottom: theme.spacing(3),
        margin: theme.spacing(5, 0, 2),
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        color: '#1D1C18',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            backgroundColor: theme.palette.primary.main,
        },
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    phoneInputContainer: {
        width: '100%',
        marginTop: '30px',
        color: theme.palette.text.main,
        border: theme.palette.text.main,
        background: "transparent",
        '& .form-control': {
            borderColor: theme.palette.borderPhoneInput,
            transition: 'border-color 0.3s ease',
            '&:focus': {
                borderColor: theme.palette.primary.main,
                outline: 'none',
                boxShadow: 'none',
                borderWidth: '2px',
            },
            '&:hover': {
                borderColor: theme.palette.borderPrimary,
            },
            '&:focus:hover': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .special-label': {
            backgroundColor: theme.palette.inputBackground,
            color: theme.palette.text.main,
            marginLeft:"-11px"
        },
    },
    phoneInputError: {
        '& .special-label': {
            color: theme.palette.error.main,
        },
        '& .form-control': {
            borderColor: `${theme.palette.error.main} !important` ,
        },
    },
}));

const UserSchema = Yup.object().shape({
    name: Yup.string().min(2, "Muy corto!").max(50, "Muy largo!").required("Requerido"),
    email: Yup.string().email("Email inválido").required("Requerido"),
    password: Yup.string().min(5, "Contraseña muy corta").max(50, "Contraseña muy larga").required("Requerido"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir').required("Requerido"),
    phone: Yup.string().required("Requerido"),
});


const RegisterForm = () => {
    const classes = useStyles();
    const { handleLogin } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const theme = useTheme();

    const initialValues = { name: "", email: "", password: "", confirmPassword: "", phone: "" };
    const dueDate = moment().add(15, "day").format();

    useEffect(() => {
        const specialLabel = document.querySelector('.special-label');
        if (specialLabel && specialLabel.textContent === 'Phone') {
            specialLabel.textContent = 'Teléfono';
        }
    }, []);

    const handleSubmit = async values => {
        Object.assign(values, { recurrence: "MENSAL" });
        Object.assign(values, { dueDate: dueDate });
        Object.assign(values, { status: "t" });
        Object.assign(values, { campaignsEnabled: true });

        try {
            setLoading(true);
            const planList = await openApi.get("/plans/list");
            const plan = planList.data[0].id;
            Object.assign(values, { planId: plan });
            await openApi.post("/companies/cadastro", values);
            toast.success("Cuenta creada correctamente. Revisar su correo para verificar su cuenta.");
            handleLogin({ email: values.email, password: values.password });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    return (
        <div className={classes.paper}>
            <div className={classes.logo}>
                <VenttifyIcon fill={theme.palette.primary.main} />
                <Typography component="p" variant="subtitle1" style={{ fontWeight: "bold", padding: "5px" }}>
                    Venttify
                </Typography>
            </div>
            <Typography component="h1" variant="h5" className={classes.title}>
                Crea tu cuenta
            </Typography>
            <Formik
                initialValues={initialValues}
                validationSchema={UserSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, setFieldValue, setFieldTouched }) => (
                    <Form className={classes.form}>
                        <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            id="name"
                            name="name"
                            label="Nombre de la empresa"
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            className={classes.input}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Business color="disabled" />
                                    </InputAdornment>
                                ),
                                placeholder: 'Ingresa tu empresa'
                            }}
                        />
                        <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            id="email"
                            name="email"
                            label="Correo electrónico"
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            className={classes.input}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                                placeholder: 'Ingresa tu correo electrónico'
                            }}
                        />
                        <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            className={classes.input}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockOutlined color="disabled" />
                                    </InputAdornment>
                                ),
                                placeholder: 'Ingresa tu contraseña',

                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            name="confirmPassword"
                            label="Confirmar Contraseña"
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            className={classes.input}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockOutlined color="disabled" />
                                    </InputAdornment>
                                ),
                                placeholder: 'Confirma tu contraseña',
                            }}
                        />
                        <PhoneInput
                            country={'mx'}
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: false,
                                color: theme.palette.text.main,
                                label: 'Teléfono'
                            }}
                            localization={es}
                            containerClass={`${classes.phoneInputContainer} ${touched.phone && errors.
                                phone ? classes.phoneInputError : ''}`}
                            inputStyle={{
                                width: '100%',
                                height: '56px',
                                fontSize: '16px',
                                paddingLeft: '48px',
                                background: theme.palette.inputBackground,
                                color: theme.palette.text.main,
                            }}
                            buttonStyle={{
                                color: theme.palette.text.main,
                                backgroundColor: 'transparent',
                                border: 'none',
                                position: 'absolute',
                                left: '0',
                                top: '0',
                                height: '56px',
                            }}
                            onChange={(phone) => setFieldValue('phone', phone)}
                            onBlur={() => setFieldTouched('phone', true)}
                            isValid={() => !errors.phone}
                        />
                        {touched.phone && errors.phone && (
                            <div style={{ color: '#E73C29', margin: '2px 0px 0px 30px', width: '100%', fontSize:"12px" }}>
                                {errors.phone}
                            </div>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Registrarse
                        </Button>
                        <Grid container style={{ fontWeight: "bold" }} >
                            <Grid item>
                                ¿Ya tienes una cuenta?
                                <Link
                                    variant="body1"
                                    component={RouterLink}
                                    to="/login"
                                    style={{ marginLeft: "5px", fontWeight: "bold" }}
                                >
                                    Inicia sesión
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RegisterForm;