const UserBorderIcon = ({ fill = "none", width = "32", height = "32" }) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="12" fill={fill} />
      <path d="M17 17C19.0711 17 20.75 15.3211 20.75 13.25C20.75 11.1789 19.0711 9.5 17 9.5C14.9289 9.5 13.25 11.1789 13.25 13.25C13.25 15.3211 14.9289 17 17 17Z" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M23.4426 24.5C23.4426 21.5975 20.5551 19.25 17.0001 19.25C13.4451 19.25 10.5576 21.5975 10.5576 24.5" stroke="#292D32" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export default UserBorderIcon