const WhatsAppIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.175 15.45C6.3 16.125 7.65 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 10.35 1.875 11.625 2.475 12.75L1.94044 14.806C1.74572 15.5549 2.43892 16.2317 3.18299 16.0191L5.175 15.45Z" stroke={fill} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.375 11.1364C12.375 11.2579 12.348 11.3828 12.2905 11.5043C12.233 11.6258 12.1587 11.7405 12.0607 11.8485C11.895 12.0308 11.7125 12.1624 11.5063 12.2468C11.3035 12.3311 11.0838 12.375 10.8472 12.375C10.5024 12.375 10.134 12.294 9.74531 12.1286C9.3566 11.9633 8.96789 11.7405 8.58256 11.4604C8.19385 11.1769 7.82543 10.863 7.4739 10.5154C7.12575 10.1644 6.8114 9.7965 6.53086 9.41175C6.25369 9.027 6.03061 8.64225 5.86837 8.26088C5.70612 7.87613 5.625 7.50825 5.625 7.15725C5.625 6.92775 5.66556 6.70838 5.74668 6.50588C5.8278 6.3 5.95625 6.111 6.13539 5.94225C6.35172 5.72963 6.58832 5.625 6.83845 5.625C6.93309 5.625 7.02773 5.64525 7.11223 5.68575C7.20011 5.72625 7.27785 5.787 7.3387 5.87475L8.12287 6.97838C8.18371 7.06275 8.22765 7.14038 8.25807 7.21463C8.2885 7.2855 8.3054 7.35638 8.3054 7.4205C8.3054 7.5015 8.28174 7.5825 8.23441 7.66013C8.19047 7.73775 8.12625 7.81875 8.04513 7.89975L7.78824 8.16637C7.75106 8.2035 7.73416 8.24738 7.73416 8.30138C7.73416 8.32838 7.73754 8.352 7.7443 8.379C7.75444 8.406 7.76458 8.42625 7.77134 8.4465C7.83219 8.55787 7.93697 8.703 8.08569 8.8785C8.23779 9.054 8.40004 9.23288 8.5758 9.41175C8.75833 9.59063 8.93409 9.756 9.11323 9.90788C9.289 10.0564 9.43434 10.1576 9.54926 10.2184C9.56616 10.2251 9.58644 10.2352 9.6101 10.2454C9.63714 10.2555 9.66418 10.2589 9.6946 10.2589C9.75207 10.2589 9.79601 10.2386 9.83319 10.2015L10.0901 9.94838C10.1746 9.864 10.2557 9.79988 10.3334 9.75938C10.4112 9.71213 10.4889 9.6885 10.5734 9.6885C10.6376 9.6885 10.7052 9.702 10.7796 9.73238C10.854 9.76275 10.9317 9.80663 11.0162 9.864L12.135 10.6571C12.2229 10.7179 12.2837 10.7887 12.3209 10.8731C12.3547 10.9575 12.375 11.0419 12.375 11.1364Z" fill={fill}/>
    </svg>

  )
}

export default WhatsAppIcon