const TagsIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.1273 11.4751L6.5248 14.8726C7.9198 16.2676 10.1848 16.2676 11.5873 14.8726L14.8798 11.5801C16.2748 10.1851 16.2748 7.9201 14.8798 6.5176L11.4748 3.1276C10.7623 2.4151 9.7798 2.0326 8.7748 2.0851L5.0248 2.2651C3.5248 2.3326 2.3323 3.5251 2.2573 5.0176L2.0773 8.7676C2.0323 9.7801 2.4148 10.7626 3.1273 11.4751Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.125 9C8.16053 9 9 8.16053 9 7.125C9 6.08947 8.16053 5.25 7.125 5.25C6.08947 5.25 5.25 6.08947 5.25 7.125C5.25 8.16053 6.08947 9 7.125 9Z" stroke={fill} stroke-width="1.5" stroke-linecap="round" />
    </svg>


  )
}

export default TagsIcon