import {
  CssBaseline,
  Container,
} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { SideImage } from "../../components/SideImage/SideImage";
import LoginForm from "../../forms/LoginForm/LoginForm";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import RegisterForm from '../../forms/RegisterForm/RegisterForm';
import ResetPasswordForm from '../../forms/ResetPasswordForm/ResetPasswordForm';
import FormVerifyAccount from '../../forms/VerifyAccountForm/VerifyAccountForm';


const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
  },
  formContainer: {
    width: '40%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    marginTop: "50px",
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  sideImageContainer: {
    width: '60%',
    height: '100%',
    display: 'flex',
    padding: "10px",
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const location = useLocation();
  const { token } = useParams();

  const renderForm = () => {
    switch (location.pathname) {
      case '/login':
        return <LoginForm />;
      case '/signup':
        return <RegisterForm />;
      case `/verify/${token}`:
        return <FormVerifyAccount />;
      case `/change-password`:
      case `/change-password/${token}`:
        return <ResetPasswordForm token={token} />;
      default:
        return <LoginForm />;
    }
  };

  return (
    <Container component="main" maxWidth={false} disableGutters style={{ height: '100vh' }}>
      <CssBaseline />
      <Box className={classes.containerWrapper}>
        <Box className={classes.formContainer}>
          {renderForm()}
        </Box>
        <Box className={classes.sideImageContainer}>
          <SideImage />
        </Box>
      </Box>
    </Container>
  );
};

export default Login;