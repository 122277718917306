import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Button,
    TextField,
    Grid,
    Typography,
    InputAdornment,
    IconButton,
    Link
} from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import { AuthContext } from "../../context/Auth/AuthContext.js";
import VenttifyIcon from "../../assets/icon/VenttifyIcon.js";
import  {EmailIcon}  from "../../assets/icon/EmailIcon.js";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "70%",
        marginTop:"40px"
    },
    form: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(3),
        fontWeight: 'bold',
    },
    input: {
        '& .MuiInputBase-input': {
            '&::placeholder': {
                color: theme.palette.text.main,
                opacity: 1,
            },
        },
        [theme.breakpoints.down('sm')]: {
          fontSize:"14px"
        },
    },
    submit: {
        fontWeight: "bold",
        fontSize:"16px",
        textTransform: 'none',
        marginBottom: theme.spacing(3),
        margin: theme.spacing(4, 0, 2),
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        color: '#1D1C18',
        '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            backgroundColor: theme.palette.primary.main,
        },
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const LoginForm = () => {
    const classes = useStyles();
    const [user, setUser] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const { handleLogin } = useContext(AuthContext);
    const theme = useTheme();

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
        handleLogin(user);
    };

    return (
        <div className={classes.paper}>
            <div className={classes.logo}>
                <VenttifyIcon fill={theme.palette.primary.main}/>
                <Typography component="p" variant="subtitle1" style={{fontWeight: "bold", padding: "5px"}}>
                    Venttify
                </Typography>
            </div>
            <Typography component="h1" variant="h5" style={{fontWeight: "bold"}}>
                Ingresa a tu cuenta
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo electrónico"
                    name="email"
                    value={user.email}
                    onChange={handleChangeInput}
                    autoComplete="email"
                    autoFocus
                    InputProps={{
                        classes: { root: classes.input },
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                        placeholder: 'Ingresa tu correo electrónico'
                    }}
                    InputLabelProps={{
                        classes: { root: classes.input },
                        shrink: true,
                    }}
                    style={{ marginTop: "30px" }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    id="password"
                    value={user.password}
                    onChange={handleChangeInput}
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        classes: { root: classes.input },
                        startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start">
                                 <LockOutlined color="disabled" />
                            </InputAdornment>
                        ),
                        placeholder: 'Ingresa tu contraseña'
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginTop: "35px" }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                >
                    Ingresar
                </Button>
                <Grid container style={{fontWeight: "bold"}} >
                    <Grid item>
                    ¿No tienes cuenta?
                        <Link
                            variant="body1"
                            component={RouterLink}
                            to="/signup"
                            style={{marginLeft:"5px", fontWeight: "bold"}}
                        >
                             Regístrate
                        </Link>
                    </Grid>
                </Grid>
                <Grid container style={{fontWeight: "bold"}} >
                    <Grid item>
                    ¿No puedes acceder a tu cuenta?
                        <Link
                            variant="body1"
                            component={RouterLink}
                            to="/change-password"
                            style={{marginLeft:"5px", fontWeight: "bold"}}
                        >
                             Restablece tu contraseña
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default LoginForm;