const HelpIcon = ({ fill = "none", width = "18", height = "18" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.09504 13.8676V11.6776C4.09504 10.9501 4.66504 10.2976 5.47504 10.2976C6.20254 10.2976 6.85504 10.8676 6.85504 11.6776V13.7851C6.85504 15.2476 5.64004 16.4626 4.17754 16.4626C2.71504 16.4626 1.50004 15.2401 1.50004 13.7851V9.1651C1.41754 4.9501 4.74754 1.5376 8.96254 1.5376C13.1775 1.5376 16.5 4.9501 16.5 9.0826V13.7026C16.5 15.1651 15.285 16.3801 13.8225 16.3801C12.36 16.3801 11.145 15.1651 11.145 13.7026V11.5951C11.145 10.8676 11.715 10.2151 12.525 10.2151C13.2525 10.2151 13.905 10.7851 13.905 11.5951V13.8676" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export default HelpIcon