import React, { useState, useEffect, useReducer, useContext } from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { i18n } from "../../translate/i18n";
import IconButton from "@material-ui/core/IconButton";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal/";
import ContactModal from "../../components/ContactModal";
import TableManager from "../../components/TableManager/TableManager";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import TableWhatsAppIcon from "../../assets/icon/TableWhatsAppIcon";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import { socketConnection } from "../../services/socket";
import ArrowUpIcon from "../../assets/icon/ArrowUpIcon";
import AddIcon from "../../assets/icon/AddIcon";
import ArrowDownIcon from "../../assets/icon/ArrowDownIcon";
import { ButtonGroup, Typography } from "@material-ui/core";
import ImportExcelButton from "../../components/ImportExcelButton";
import toastError from "../../errors/toastError";
import NewTicketModal from "../../components/NewTicketModal";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));


const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_CONTACTS':
      return [...state, ...action.payload.filter(contact => !state.find(c => c.id === contact.id))];
    case 'UPDATE_CONTACT':
      return state.map(c => c.id === action.payload.id ? action.payload : c);
    case 'DELETE_CONTACT':
      return state.filter(c => c.id !== action.payload);
    case 'RESET':
      return [];
    default:
      return state;
  }
};

const ContactsTable = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [importConfirmOpen, setImportConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [importOption, setImportOption] = useState(null);



  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenDeleteModal = (contact) => {
    setDeletingContact(contact);
    setDeleteConfirmOpen(true);
  };

  const handleOpenImportModal = () => {
    setImportOption(null);
    setImportConfirmOpen(true);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
      dispatch({ type: "DELETE_CONTACT", payload: contactId });
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
  };



  const handleimportContact = async () => {
    try {
      if (importOption === 'phone') {
        await api.post("/contacts/import");
        toast.success(i18n.t("contacts.toasts.importSuccess"));
      }
      dispatch({ type: "RESET" });
      setPageNumber(1);
    } catch (err) {
      toastError(err);
    }
    setImportOption(null);
    setImportConfirmOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const primaryButtons = [
    { label: 'Agregar Contacto', onClick: handleOpenContactModal, icon: <AddIcon fill='#000' /> },
  ];

  const secondaryButtons = [
    { label: 'Importar Contactos', onClick: handleOpenImportModal, icon: <ArrowUpIcon fill={theme.palette.primary.main} /> },
  ];
  const csvData =
  {
    icon: <ArrowDownIcon fill={theme.palette.primary.main} />,
    label: 'Exportar Contactos',
    data:
      contacts.map(contact => ({
        name: contact.name,
        number: contact.number,
        email: contact.email,
      })),
    separator: ";",
    filename: 'Contactos.csv'
  }
  const columns = [
    { field: 'avatar', headerName: 'Nombre',headerStyle: {fontWeight: '400'} },
    { field: 'number', headerName: 'WhatsApp', align: 'center',width: '25%',headerStyle: {fontWeight: '400'} },
    { field: 'email', headerName: 'Correo Electrónico', align: 'center',width: '25%',headerStyle: {fontWeight: '400'} },
  ];

    const actionCellStyle = {
    width: '12%',
  };

  const actionButtons = [
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setContactTicket(row);
          setNewTicketModalOpen(true);
        }}
      >
        <TableWhatsAppIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => hadleEditContact(row.id)}
      >
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => handleOpenDeleteModal(row)}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  return (
    <>
      <div className={classes.mainContainer}  >
        <ConfirmationModal
          title="Eliminar Contacto"
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          onConfirm={() => handleDeleteContact(deletingContact?.id)}
        >
          ¿Estás seguro de que deseas eliminar este contacto?
        </ConfirmationModal>

        <ContactModal
          open={contactModalOpen}
          onClose={handleCloseContactModal}
          aria-labelledby="form-dialog-title"
          contactId={selectedContactId}
        ></ContactModal>

        <NewTicketModal
          modalOpen={newTicketModalOpen}
          initialContact={contactTicket}
          onClose={(ticket) => {
            handleCloseOrOpenTicket(ticket);
          }}
        />

        <ConfirmationModal
          title={i18n.t("contacts.confirmationModal.importTitle")}
          open={importConfirmOpen}
          onClose={() => {
            setImportConfirmOpen(false);
            setImportOption(null);
          }}
          onConfirm={handleimportContact}
        >
          <Typography>{i18n.t("contacts.confirmationModal.importMessage")}</Typography>
          <ButtonGroup variant="contained" color="primary" style={{ marginTop: '1rem' }}>
            <ImportExcelButton
              endpoint="/contacts/import-excel"
              onSuccess={(data) => {
                toast.success(i18n.t("contacts.toasts.importSuccess"));
                dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                setImportConfirmOpen(false);
              }}
              onError={(err) => {
                toastError(err);
              }}
              buttonText={i18n.t("contacts.buttons.importExcel")}
            />
            <Button
              onClick={() => {
                setImportOption('phone')
                setImportConfirmOpen(false)
              }}
              variant='contained'
            >
              {i18n.t("contacts.buttons.importPhone")}
            </Button>
          </ButtonGroup>
        </ConfirmationModal>

        <div className={classes.tableContainer}>
          <HeaderManager
            title="Contactos"
            primaryButtons={primaryButtons}
            secondaryButtons={secondaryButtons}
            onSearch={handleSearch}
            csvData={csvData}
          />
          <TableManager
            columns={columns}
            actionCellStyle={actionCellStyle}
            data={contacts}
            loading={loading}
            onScroll={loadMore}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default ContactsTable;