import React, { useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
  Facebook,
  Instagram,
  WhatsApp,
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import TrashIcon from "../../assets/icon/TrashIcon";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));



const CustomToolTip = ({ title, content, children }) => {
  return (
    <Tooltip
      arrow
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const IconChannel = (channel) => {
  switch (channel) {
    case "whatsapp":
      return <WhatsApp />;
    default:
      return "ID";
  }
};

const Connections = () => {
  const classes = useStyles();
  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
  });

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, []);

  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, []);

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo({
      action: "",
      title: "",
      message: "",
      whatsAppId: "",
    });
    setConfirmModalOpen(false);
  };

  const renderActionButtons = (whatsApp) => {
    if (whatsApp.status === "qrcode") {
      return (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleOpenQrModal(whatsApp)}
        >
          {i18n.t("connections.buttons.qrcode")}
        </Button>
      );
    }
    if (whatsApp.status === "DISCONNECTED") {
      return (
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => handleRequestNewQrCode(whatsApp.id)}
        >
          {i18n.t("connections.buttons.newQr")}
        </Button>
      );
    }
    if (["CONNECTED", "PAIRING", "TIMEOUT"].includes(whatsApp.status)) {
      return (
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            handleOpenConfirmationModal("disconnect", whatsApp.id);
          }}
        >
          {i18n.t("connections.buttons.disconnect")}
        </Button>
      );
    }
    if (whatsApp.status === "OPENING") {
      return (
        <Button size="small" variant="outlined" disabled color="default">
          {i18n.t("connections.buttons.connecting")}
        </Button>
      );
    }
    return null;
  };

  const renderStatusToolTips = (whatsApp) => {
    if (whatsApp.status === "DISCONNECTED") {
      return (
        <CustomToolTip
          title={i18n.t("connections.toolTips.disconnected.title")}
          content={i18n.t("connections.toolTips.disconnected.content")}
        >
          <SignalCellularConnectedNoInternet0Bar color="secondary" />
        </CustomToolTip>
      );
    }
    if (whatsApp.status === "OPENING") {
      return <CircularProgress size={24} />;
    }
    if (whatsApp.status === "qrcode") {
      return (
        <CustomToolTip
          title={i18n.t("connections.toolTips.qrcode.title")}
          content={i18n.t("connections.toolTips.qrcode.content")}
        >
          <CropFree />
        </CustomToolTip>
      );
    }
    if (whatsApp.status === "CONNECTED") {
      return (
        <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
          <SignalCellular4Bar style={{ color: green[500] }} />
        </CustomToolTip>
      );
    }
    if (["TIMEOUT", "PAIRING"].includes(whatsApp.status)) {
      return (
        <CustomToolTip
          title={i18n.t("connections.toolTips.timeout.title")}
          content={i18n.t("connections.toolTips.timeout.content")}
        >
          <SignalCellularConnectedNoInternet2Bar color="secondary" />
        </CustomToolTip>
      );
    }
    return null;
  };

  const primaryButtons = [
    {
      label: "Agregar conexión",
      render: ({ onClick }) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                {...bindTrigger(popupState)}
              >
                Agregar conexión
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    handleOpenWhatsAppModal();
                    popupState.close();
                    onClick();
                  }}
                >
                  <WhatsApp fontSize="small" style={{ marginRight: "10px" }} />
                  WhatsApp
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
    },
  ];

  const columns = [
    {
      field: 'channel',
      headerName: 'Canal',
      renderCell: ({ row }) => IconChannel(row.channel)
    },
    { field: 'name', headerName: i18n.t("connections.table.name") },
    {
      field: 'status',
      headerName: i18n.t("connections.table.status"),
      renderCell: ({ row }) => renderStatusToolTips(row)
    },
    {
      field: 'session',
      headerName: i18n.t("connections.table.session"),
      renderCell: ({ row }) => renderActionButtons(row)
    },
    {
      field: 'updatedAt',
      headerName: i18n.t("connections.table.lastUpdate"),
      renderCell: ({ row }) => format(parseISO(row.updatedAt), "dd/MM/yy HH:mm")
    },
    {
      field: 'isDefault',
      headerName: i18n.t("connections.table.default"),
      renderCell: ({ row }) => row.isDefault && <CheckCircle style={{ color: green[500] }} />
    },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => handleEditWhatsApp(row)}
      >
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => handleOpenConfirmationModal("delete", row.id)}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  return (
    <>
      <div className={classes.mainContainer} >
        <ConfirmationModal
          title={confirmModalInfo.title}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleSubmitConfirmationModal}
        >
          {confirmModalInfo.message}
        </ConfirmationModal>
        <QrcodeModal
          open={qrModalOpen}
          onClose={handleCloseQrModal}
          whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
        />
        <WhatsAppModal
          open={whatsAppModalOpen}
          onClose={handleCloseWhatsAppModal}
          whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
          whatsApps={whatsApps}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("connections.title")}
            primaryButtons={primaryButtons}
          />
          <TableManager
            columns={columns}
            data={whatsApps}
            loading={loading}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default Connections;