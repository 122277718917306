import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muy corto!")
		.max(50, "Too Long!")
		.required("Requerido"),
	password: Yup.string().min(5, "Muy corto!").max(50, "Too Long!").required("Requerido"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserEditSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muy corto!")
		.max(50, "Too Long!")
		.required("Requerido"),
	password: Yup.string().min(5, "Muy corto!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId, usersLength = [], adminCount }) => {
	const classes = useStyles();
	const { user: loggedInUser } = useContext(AuthContext);
	const [profileDisabled, setProfileDisabled] = useState(true);
	

	const [user, setUser] = useState({
		name: '',
		email: '',
		password: '',
		profile: '',
		languageId: ''
	  });
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);

	const totalUsers = loggedInUser?.company?.totalUsers
	const currentUsers = usersLength.length; 

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) {
				setUser({
					name: '',
					email: '',
					password: '',
					profile: '',
					languageId: ''
				});
				setSelectedQueueIds([]);
				return;
			}
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	useEffect(() => {
		const isEditingSelf = userId === loggedInUser.id;
		const isCurrentUserAdmin = loggedInUser.profile === "admin";
		const hasMultipleAdmins = adminCount > 1;

		if (isEditingSelf && isCurrentUserAdmin && !hasMultipleAdmins) {
			setProfileDisabled(true);
		} else {
			setProfileDisabled(false);
		}
	}, [adminCount, userId, loggedInUser.id, loggedInUser.profile]);

	const handleClose = () => {
		onClose();
	};

	const handleSaveUser = async values => {
		const userData = { ...values, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				if (totalUsers >currentUsers) {
					await api.post("/users", userData);
				}
				else{
					toast.error("Has alcanzado el número máximo de usuarios. Compra un usuario adicional para continuar.");
				}
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={userId ? UserEditSchema : UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									{!userId && (
										<Field
											as={TextField}
											label={i18n.t("userModal.form.password")}
											type="password"
											name="password"
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									)}
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.profile")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required
														disabled={profileDisabled}
													>
														<MenuItem value="admin">Admin</MenuItem>
														<MenuItem value="user">User</MenuItem>
													</Field>
												</>
											)}
										/>
									</FormControl>
								</div>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editQueues"
											yes={() => (
												<>
													<QueueSelect
														selectedQueueIds={selectedQueueIds}
														onChange={values => setSelectedQueueIds(values)}
													/>
												</>
											)}
										/>
									</FormControl>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<InputLabel id="language-selection-input-label">
											{i18n.t("userModal.form.language")}
										</InputLabel>

										<Field
											as={Select}
											label={i18n.t("userModal.form.language")}
											name="languageId"
											labelId="language-selection-label"
											id="language-selection"
											required
										>
											<MenuItem value="2">Español</MenuItem>
											<MenuItem value="1">Inglés</MenuItem>
										</Field>
									</FormControl>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
