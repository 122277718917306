import React, { useState, useEffect, useReducer, useRef } from "react";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import DownloadIcon from "@material-ui/icons/GetApp";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactListDialog from "../../components/ContactListDialog";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";
import HeaderManager from "../../components/HeaderTableManager/HeaderTableManager";
import TableManager from "../../components/TableManager/TableManager";
import { socketConnection } from "../../services/socket";
import { CSVLink } from "react-csv";
import { GroupAdd } from "@material-ui/icons";
import AddIcon from "../../assets/icon/AddIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90%'
    },
  },
}));




const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CONTACTLISTS":
      return action.payload;
    case "UPDATE_CONTACTLIST":
      return state.map(item => item.id === action.payload.id ? action.payload : item);
    case "DELETE_CONTACTLIST":
      return state.filter(item => item.id !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};

const ContactLists = () => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedContactList, setSelectedContactList] = useState(null);
  const [deletingContactList, setDeletingContactList] = useState(null);
  const [contactListModalOpen, setContactListModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [contactLists, dispatch] = useReducer(reducer, []);
  const [ready, setReady] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvFilename, setCsvFilename] = useState('');
  const csvLinkRef = useRef();

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchContactLists();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-ContactList`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTLIST", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACTLIST", payload: +data.id });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (ready && csvLinkRef.current) {
      csvLinkRef.current.link.click();
      setReady(false);
    }
  }, [ready]);

  const fetchContactLists = async () => {
    try {
      const { data } = await api.get("/contact-lists/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CONTACTLISTS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchContacts = async (contactListId) => {
    try {
      const { data } = await api.get(`contact-list-items`, {
        params: { searchParam, pageNumber: 1, contactListId },
      });
      return data.contacts.map(contact => ({
        Nombre: `\t${contact.name}`,
        Numero: `\t${contact.number}`,
        "Correo Electronico": `\t${contact.email}`
      }));
    } catch (err) {
      toast.error(err.message);
      return [];
    }
  };

  const handleDownload = async (id, contactListName) => {
    const data = await fetchContacts(id);
    setCsvData(data);
    setCsvFilename(`Contactos de ${contactListName}.csv`);
    setReady(true);
  };

  const handleOpenContactListModal = () => {
    setSelectedContactList(null);
    setContactListModalOpen(true);
  };

  const handleCloseContactListModal = () => {
    setSelectedContactList(null);
    setContactListModalOpen(false);
  };

  const handleEditContactList = (contactList) => {
    setSelectedContactList(contactList);
    setContactListModalOpen(true);
  };

  const handleDeleteContactList = async (contactListId) => {
    try {
      await api.delete(`/contact-lists/${contactListId}`);
      toast.success(i18n.t("contactLists.toasts.deleted"));
      dispatch({ type: "DELETE_CONTACTLIST", payload: contactListId });
    } catch (err) {
      toast.error(err.message);
    }
    setDeletingContactList(null);
    setConfirmModalOpen(false);
  };

  const goToContacts = (id) => {
    history.push(`/contact-lists/${id}/contacts`);
  };

  const columns = [
    { field: 'name', headerName: i18n.t("contactLists.table.name"), headerStyle: { fontWeight: '400' }, width: '40%' },
    {
      field: 'contactsCount',
      headerName: i18n.t("contactLists.table.contacts"),
      renderCell: ({ row }) => row.contactsCount || 0,
      headerStyle: { fontWeight: '400' }, width: '40%'
    },
  ];

  const actionButtons = [
    ({ row }) => (
      <IconButton size="small" onClick={() => goToContacts(row.id)}>
        <AddIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        title="Descargar lista de contactos"
        onClick={() => handleDownload(row.id, row.name)}
      >
        <DownloadIcon />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton size="small" onClick={() => handleEditContactList(row)}>
        <PencilEditIcon fill="#7F7F7F" />
      </IconButton>
    ),
    ({ row }) => (
      <IconButton
        size="small"
        onClick={() => {
          setConfirmModalOpen(true);
          setDeletingContactList(row);
        }}
      >
        <TrashIcon fill="#7F7F7F" />
      </IconButton>
    ),
  ];

  const primaryButtons = [
    { label: i18n.t("contactLists.buttons.add"), onClick: handleOpenContactListModal, icon: <AddIcon fill='#000' /> },
  ];

  return (
    <>
      <div className={classes.mainContainer} >
        <ConfirmationModal
          title={i18n.t("contactLists.confirmationModal.deleteTitle")}
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={() => handleDeleteContactList(deletingContactList.id)}
        >
          {i18n.t("contactLists.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <ContactListDialog
          open={contactListModalOpen}
          onClose={handleCloseContactListModal}
          aria-labelledby="form-dialog-title"
          contactListId={selectedContactList && selectedContactList.id}
        />
        <CSVLink
          style={{ display: 'none' }}
          separator=";"
          filename={csvFilename}
          data={csvData}
          ref={csvLinkRef}
        />
        <div className={classes.tableContainer}>
          <HeaderManager
            title={i18n.t("contactLists.title")}
            primaryButtons={primaryButtons}
            onSearch={(value) => setSearchParam(value.toLowerCase())}
          />
          <TableManager
            columns={columns}
            data={contactLists}
            loading={loading}
            onScroll={() => hasMore && !loading && setPageNumber(prevState => prevState + 1)}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </>
  );
};

export default ContactLists;